// src/ComingSoon.js
import "./ComingSoon.css";

const ComingSoon = () => {
  //   const [timeRemaining, setTimeRemaining] = useState("");

  //   useEffect(() => {
  //     const countdownDate = new Date("Feb 15, 2025 00:00:00").getTime();

  //     const interval = setInterval(() => {
  //       const now = new Date().getTime();
  //       const distance = countdownDate - now;

  //       if (distance <= 0) {
  //         clearInterval(interval);
  //         setTimeRemaining("The wait is over! We're live.");
  //       } else {
  //         const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //         const hours = Math.floor(
  //           (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //         );
  //         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //         const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  //         setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
  //       }
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   }, []);

  return (
    <div className="coming-soon-container">
      <h1>Coming Soon</h1>
      <h2>Ghar Ka Swaad, Har Din!</h2>
      <p>
        We're working hard to bring something amazing to you 🍲 <br />
        Stay tuned! 👊🏻
      </p>
      {/* Bringing the warmth of homemade food to your plate! Our platform connects
      you with passionate homemakers who prepare fresh, healthy, and delicious
      tiffins. Whether you’re a student, professional, or new to Ahmedabad,
      enjoy home-cooked meals made with love and care—just like home! */}
      <img src="lunchbox.png" alt="lunchbox" />
      {/* <div className="countdown-timer">
        <h2>{timeRemaining}</h2>
      </div> */}
    </div>
  );
};

export default ComingSoon;
